@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
  background-color: #f4f4fa;
  font-size: 15px;
}
.authPage {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.79) 0%, rgba(0, 0, 0, 0.31) 100%),
    url(../assets/images/svg/bg-login.svg);
  background-repeat: no-repeat;
  background-color: currentcolor;
  background-size: cover;
  border-radius: 10px;
}
.slider {
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.55);
}
.profile-card {
  border-radius: 21px;
}
.profile-card img {
  filter: drop-shadow(0px -1px 5px rgba(0, 0, 0, 0.25));
}

.chat-box {
  background: RGBA(16, 126, 195, 0.08);
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

main:hover {
  color: rgba(0, 0, 0, 0.3);
}

main {
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease;
}
*,
*:focus {
  outline: none;
}

.form-item {
  position: relative;
}
.form-item input {
  display: block;
  height: 40px;
  background: transparent;
  transition: all 0.3s ease;
  padding: 0 15px;
  border-radius: 0px;
}
.form-item input:focus {
  border-color: blue;
}
.form-item label {
  position: absolute;
  cursor: text;
  z-index: 1;
  top: 13px;
  left: 10px;
  background: #fff;
  padding: 0 10px;
  transition: all 0.3s ease;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.2px;
}
.form-item input:focus + label,
.form-item input:valid + label {
  font-size: 11px;
  top: -5px;
}
.form-item input:focus + label {
  color: blue;
}
.pinInput {
  border: 1px solid #CCD4DE;
  box-sizing: border-box;
  border-radius: 2px;
  max-width: 75px;
  min-height: 40px;
}


.tooltip .tooltiptext {
	width: fit-content;
	left: 105%;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #1e37f8 transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

.tooltip2 .tooltiptext {
	bottom: 190%;
	width: 72%;
	left: 0%;
}

.tooltip2 .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
	visibility: visible;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.tooltip3 {
	height: 1.7rem;
}

.tooltip3 .tooltiptext {
	color: #fff;
	right: 90%;
	width: 120px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.tooltip3 .tooltiptext img {
	/* width: 30px; */
}

.tooltip3:focus .tooltiptext {
	visibility: visible;
}
.tooltip3:hover .tooltiptext {
	visibility: visible;
}

.table-body {
  height: calc(100vh - 270px);
}

.right-sidebar {
  height: calc(100vh - 320px);
}

.integrations {
  height: calc(100vh - 213px);
}

.toggle__dot {
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #48bb78;
}


input[type="radio"] + label span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
background-color: #3490DC;
box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
 color: #3490DC; 
}

.integration-box {
  width: 208px;
  background: #FFFFFF;
  border: 1px solid #A8A9AA;
  box-sizing: border-box;
  height: 170px;
  border-radius: 10px;
}

.integration-box:hover {
  border: 1px solid #107EC3;
}

button:focus {
  outline: none!important;
}

.strike-divider {
  color: #A6AEB7;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before,
  &::after {
    content: "";
    height: 1px;
    background: #D5DDEC;
    display: block;
    width: 46%;
    font-size: 18px;
  }
}

.rc-slider {
  .rc-slider-rail {
    background-color: #C8E6C9;
    height: 16px;
  }

  .rc-slider-track {
    background-color: #4CAF50;
    height: 16px;
    border-radius: 0;
  }

  .rc-slider-step {
    height: 16px;
  }

  .rc-slider-handle {
    background: #444444;
    border-radius: 20px;
    width: 3px;
    border: 0;
    height: 25px;
  }
}

.slide-popup {
  right: -50%;
  max-width: 50%;
  height: 100%;
  right: 0px;
  top: 0px;
  -webkit-animation: slide 2s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 2s forwards;
  animation-delay: 2s;
}

@-webkit-keyframes slide {
  100% { right: 0; }
}

@keyframes slide {
  100% { right: 0; }
}

.Toastify__toast {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 26px rgba(235, 87, 87, 0.18);
  border-radius: 6px;
  padding: 20px;
  padding-left: 30px;
  &--error {
    border: 1px solid #EB5757;
    &::before {
      content: url("../assets/images/svg/fail-toast.svg");
      position:relative; /*or absolute*/
      z-index:100000; /*a number that's more than the modal box*/
      left:-12px;
      top:10px;
    }
    &::after {
      content: 'Failed';
      position: absolute;
      color: #333333;
      font-size: 15px;
      font-weight: 700;
      left:55px;
    }
  }
  &--success {
    border: 1px solid #6FCF97;
    &::before {
      content: url("../assets/images/svg/success-toast.svg");
      position:relative; /*or absolute*/
      z-index:100000; /*a number that's more than the modal box*/
      left:-12px;
      top:10px;
    }
    &::after {
      content: 'Success';
      position: absolute;
      color: #333333;
      font-size: 15px;
      font-weight: 700;
      left:55px;
    }
  }
  &-body {
    color: #4D4D4D;
    font-size: 14px;
    line-height: 20px;
    padding: 0px;
    padding-top: 25px;
    width: 100%;
  }
  button {
    background-image: url("../assets/images/svg/cross-toast.svg");
    background-size: cover;
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: none;
    }
  }
}