.post-review-section {
    font-family: 'Barlow', sans-serif;
    background-color: var(--white);
    overflow: auto;

    h3 {
        color: var(--dark-color);
        font-size: 28px;
    }

    h4 {
       color: var(--dark-color);
       font-size: 25px; 
       font-weight: 600;  
    }
     
    p, button {
      font-size: 16px;
    }
    p {
        color: #454547;
    }

    button {
        font-weight: 500;
        font-size: 16px;
        border-radius: 4px;
    }
}

.post-review-section {
    main {
        overflow: unset;
    }

    .back-to-home-btn {

        border: 1px solid #444444;
        box-sizing: border-box;
        border-radius: 4px;
    
    img {
        display: inline-block;
        margin-right: 10px;
       }
    }

    .review-left-bg {
        position: absolute;
        width: 184.49px;
        height: 377.9px;
        left: 39.25px;
        top: 75px;
    }

    .review-right-bg {
        position: absolute;
        width: 310.62px;
        height: 468.3px;
        right: 40px;
        top: 95px;
    }  

    .post-content-part {

        width: 70%;
  }

  .post-content-part .template-tabs {

    margin-top: 15px;
    border:none;
    z-index: 100;

    .rc-tabs-bar {
      // background: #F7F9FA;
      border: none;
    }

    .rc-tabs-tab {
        color: #353449;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding: 8px 10px;
        margin-right: 20px;

        svg {
            fill: #353449;
            margin-right: 0;
            width: 16px;
        }

        .seperator {
          width: 1px;
          margin-left: 5px;
          margin-right: 8px;
          background: #353449;
          height: 8px;
        }

        .tick-img {
          margin-left: 10px;
        }
    }

    .rc-tabs-ink-bar {
        // display: none !important;
        background: #1877F2;
        height: 6px;
        border-radius: 3px 3px 0px 0px;
    }

    .rc-tabs-tabpane {
        margin-top: 15px;
    }

    .rc-tabs-tab-active, .rc-tabs-tab-active:hover {
        // background: #365BAC;
        color: #1877F2;
        position: relative;

        svg {
            fill: #1877F2;
        }

        // .seperator {
        //     background: var(--white);
        // }
    }
    
    // .rc-tabs-tab-active::before {
    //     top: 35px;
    //     content: "";
    //     position: absolute;
    //     height: 11px;
    //     width: 11px;
    //     left: 45%;
    //     background: #365BAC;
    // }

      .choose-template-lbl {
        color: var(--dark-color);
      }

      .template-text-section {
        color: #454547;
        padding: 1rem;
        font-size: 16px;
        border: 1px solid #D8E4EB;
        border-radius: 4px;
        background: #FBFBFB;

        .selected-hashtag {
          background: #1F4A82;
          max-width: 80px;
          padding: 5px;
          color: var(--white);
      }

      .tagged-user {
        color: #2D88FF;
      }
      }

      .template-text-section.disabled {
        background: #E4ECF1;
      }
}

  .submit-post-btn {
     background: var(--orange);
     color: var(--white);
     width: 152px;
     margin-top: 20px;
     font-weight: 600;
  }

  .submit-post-btn.disabled {
    background: #F8AD97;
    color: #444444;
    cursor: default;
  }
}


@media screen and (max-width: 1024px) {

  .post-review-section .review-left-bg {
      left: -70px;
      top: 0;
  }

  .post-review-section .review-right-bg {
      right: -93px;
      top: 25%;
  }

  .post-review-section .post-content-part {
    padding-left: 3rem;
    padding-right: 3rem;
  }
    
}

@media screen and (max-width: 768px) {
  
    .post-review-section .review-right-bg {
        right: -150px;
    }
  
    .post-review-section .post-content-part {
      padding-left: 2rem;
    }

    .post-content-part {
      h4, p {
        text-align: center;
      }
    }
}

@media screen and (max-width: 532px) {
    .post-review-section .review-left-bg { 
      display: none;
    }

    .post-review-section .review-right-bg {
      display: none;
    }

    .post-review-section .post-content-part {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
    }
}
