#root {
  font-family: 'Barlow', sans-serif;
}
.Toastify__toast button {
  background-position: center;
}
.h-82 {
 height: 85%;
}

.content-part {
  width: 80%;
}

.header-80 {
  height: 15%;
  width: 100%;
}

.sidebar-profile {
  height: 15%;
}

.bx-shadow-right {
  box-shadow: 0px 4px 16px rgba(237, 92, 48, 0.12);
}

.bx-shadow-btm {
  box-shadow: 0px 4px 16px rgba(237, 92, 48, 0.12);
}

.menu-item {
  border: 1px solid #1A1A1A;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;

h1 {
   color: #1A1A1A;
}

 svg {
    fill: #1A1A1A;
    margin-right: 1em;
  }
}

.content-part-h-82 {
  height: 85%;
  width: 100%;
}

.mobile-nav-toggle {
  // position: fixed;
  // top: 110px;
  // left: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px !important;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  margin-right: 20px;
  margin-left: 8px;
  display: none;

  img {
    height: 24px;
  }
}

.menu-item .activeClass {
  background: #1A1A1A;
  border: none;
  box-sizing: border-box;
  color: #fff;
   h1 {
     color: #ffffff;
    }
   svg {
     fill: #ffffff;
    }
}

.add-review-title {
    .title {
      color: #1F100C;
      font-weight: 500;
      font-size: 25px;
      line-height: 20px;
    }
}

.divider {
   background: #EBE7DD;
   height: 1px ;

}

.form-container {
  color: #524C4B;

  .form-group {
    width: 65%;

    label {
      font-weight: 500;
    }

    .lbl-name {
      color: #444444;
    }

    .lbl-error {
      color: red;
    }
  }

  .project-name-input {
    width: 100%;
    border: 1px solid #ACACAC;
    height: 40px;
    border-radius: 6px;
  }

  .project-name-input.error {
    border-color: red;
  }

  .input-feedback {
    color: red;
    margin-top: .25rem;
    text-align: right;
  }

  .sharing-platform-accordion {
   width: 65%;

   .accordion__panel:hover {
    cursor: pointer;
    color: #000000;
   }
  }

  .template-options {
    .Select-value-label {
      color: #FF6E43 !important
    }
  }

  .template-options.error {
    border-color: red;
  }

  .create-link-btn {
    background: #FF6D41;
    border: 1px solid #FF6D41;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    font-family: Work Sans;
    font-weight: 500;
    min-width: 175px;
    height: 42px;
  }
}

.w-65 {
  width: 65%;
}

.tooltip-1 {
  position: relative;
  display: inline-block;
 // border-bottom: 1px dotted black;
}

.tooltip-1 .tooltiptext {
  visibility: hidden;
  width: 300px;
  max-height: 500px;
  background-color: #444444;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  /* Position the tooltip */
  position: absolute;
  z-index: 20;
  bottom: 170%;
  left: 50px;
  margin-left: -60px;
}

.tooltip-1 .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 6%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #444444 transparent transparent transparent;
}

.tooltip-1:hover .tooltiptext {
  visibility: visible;
}

.template-tabs {

  border: none;
  margin-top: 20px;

  .rc-tabs-bar {
    border: none;
  }

  .rc-tabs-tab {
    color: #444444;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-family: 'Work Sans', 'sans-serif';

    svg {
      fill: #444444;
      margin-right: 12px;
    }
  }

  .rc-tabs-ink-bar {
    background: #1877F2;
    height: 6px;
    border-radius: 3px 3px 0px 0px;
  }

  .rc-tabs-nav-wrap {
    margin-bottom: 10px;
    border-bottom: 2px solid #E9EEF5;
  }

  .rc-tabs-content {
    margin-top: 10px;
  }

  
  .rc-tabs-tab-active, .rc-tabs-tab-active:hover, .rc-tabs-tab:hover {
    color: #1877F2;
    svg {
      fill: #1877F2;
    }
  }

  .template-text-section {
    border: 1px solid #ACACAC;
    border-radius: 6px;
    padding: 1rem;
    text-align: justify;
    min-height: 200px;
  }
}

.choose-template-lbl {
margin-bottom: 6px;
font-weight: 500;
}

.template-text {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  color: #454547;
  font-size: 16px;
}

.copy-link-section {

  width: 65%;
  input {
    width: 75%;
    border: 1px solid #CCC5C4;
    border-radius: 6px;
  }

  .copy-link-btn {
    background: #1E88E5;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Work Sans';
    height: 40px;
    width: 137px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 5px;
    }
  }

  .copy-link-btn:disabled {
    cursor: default;
  }
}


@media (max-width: 769px) {

  .review-interface  {
    flex-direction: column;
  }

  .review-interface aside {
    width: 100%;
    height: 15%;
  }

  .sidebar-profile {
    height: 100%;
  }

  .side-nav {
    display: none !important;
  }

  .mobile-nav-toggle {
    display: inline-block;
  }


  /* ---Sidebar css */
.text-primary{
  color: #5a8dee!important;
}
.btn-primary {
    border-color: none;
    background-color: none;
    color: #1A1A1A;
    font-size: 40px;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 300px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 10000;
    transition: 0.5s;
}
.sidebar.active {
    left: 0;
}
.sd-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}
.sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li {
    list-style: none;
    margin-bottom: 25px;
    margin-top: 25px;
}
.sd-body ul li .sd-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
}

.sd-link.active {
  background: #1A1A1A !important;
  color: #fff !important;

  svg {
    fill: #fff;
  }
}

  .header-80 {
    display: none;  
  }

  .content-part {
    width: 100% !important;
    height: 85% !important;
  }

  .content-part-h-82 {
    height: 100%;
  }

 .form-container {
  .form-group {
    width: 100%;
  }
 } 

 .tooltip-1 .tooltiptext {
   width: 130px;
   left: -12px;
   overflow:auto;
 }

 .copy-link-section {
  width: 90%;
 }

//  .RRT__container {
//   width: 90%;
// }
}

@media (max-width: 1200px) and (min-width: 769px) {
  .form-container {
    .form-group {
      width: 85%;
    }
   } 

   .copy-link-section {
    width: 85%;
   }
  
  //  .RRT__container {
  //   width: 85%;
  // } 
}

@media screen and (max-width: 532px) {
  *{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}

.review-interface {

  .content-part {
    .form-container {
      padding-right: 0;
      padding-left: 0;
    }
  }


.copy-link-section {
  flex-direction: column;

  input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .copy-link-btn {
    margin-left: 0.5rem;
  }
}
}
}


