:root {
    --white: #FFFFFF;
    --orange: #FF6E43;
    --dark-color: #1F1F22;
  }

.thanks-page {
    font-family: 'Barlow', sans-serif;
    background: #FFFFFF;
    h3, h4, p, li {
        color: var(--dark-color);
    }

    h4 {
        font-size: 33px;
        font-weight: 600;
    }

    p {
      font-size: 18px;
      font-weight: 400;
    }

    button {
        font-size: 16px;
    }

    button {
        border-radius: 4px;
        font-weight: 500; 
        background: #FF6D41;

    }

    main {
        overflow: unset;
    }
  }

.endorse-page-header {

    height: 860px;
    position: relative;

    .header-bg {
      background: #365BAC;
      border-radius: 14px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .header-part-1 {
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%), linear-gradient(0deg, #F2E0DA, #F2E0DA);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, #FFFFFF 100%), linear-gradient(0deg, #FFF8DC, #FFF8DC);
        border-radius: 12px;
        height: 60%;

        .title-container{
          height: 85%;
        }

        .header-title {
            font-size: 33px;
            font-weight: 700;
            white-space: break-spaces;
            span {
            color: var(--orange);
            font-size: 20px;
            }
        }
    
        p {
            font-size: 18px;
            font-weight: 400;
            color: #454547;
            margin-top: 25px;
            text-align: left;
            span {
              color: var(--orange);
              font-weight: 700;
            }
        }

        .endrose-now-btn {
            width: 200px;
            background: var(--orange);
            color: var(--white);
            font-size: 16px;
            font-weight: 700;
            border-radius: 4px;
            padding: 10px;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        img {
            z-index: 100;
        }
    }


    .heaader-part-2 {
        height: 40%;
        background: #365BAC;
        // #379BE7;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .gift-voucher-img {
            position: relative;
        }

        .gift-voucher-part {
            height: 100%;

            figure {
                align-self: flex-start;
            }
        }

        .gift-voucher-part .tag-img-name {
            position: absolute;
            top: 52%;
            left: 33%;
            font-family: 'Barlow', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: center;

            span {
                color: #FF6E43;
                font-size: 25px;
                font-weight: 600;
            }
        }

        // figure::before {
        //     content: '';
        //     position: absolute;
        //     left: 53%;
        //     top: -81px;
        //     background: var(--white);
        //     height: calc(100% - 80px);
        //     width: 8px;
        // }

        p {
            font-size: 18px;
            font-weight: 400;
            color: var(--white);
            text-align: left;
        }

        span {
            font-weight: 600;
        }
    }
}

.endrose-section {
    h3 {
        font-size: 44px;
        margin: 0 auto;
        padding: 2em 1em 1em;
        font-weight: 600;
    }

    .bg-circle-shape-left {
        position: absolute;
        width: 608px;
        height: 608px;
        left: -100px;
        top: 20px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        z-index: -1;
    }

    img {
        height: 350px;
        z-index: 0;
    }

    video {
        height: 360px;
        width: 520px;
    }

    .left-circle-bg-image {
        height: auto;
        z-index: 0;
        position: absolute;
        left: 0;
    }

    .right-circle-bg-image {
        position: absolute;
        z-index: 0;
        height: auto;
        right: 0;
        bottom: -50px;
    }

    .social-media-section-title span {
       color: var(--orange);
    }

.procedure-list {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    color: #454547;

    li {
        position: relative;
        padding-left: 20px;    
        margin: 18px 0;
        font-size: 18px;
        line-height: 25.6px;
    }

     li:before {
        // content: '';
        // width: 13px;
        // height: 13px;
        // position: absolute;
        // background: url('../../assets/images/png/Bullet.png') 50% 50% no-repeat;
        // background-size: cover;
        // // background-position: center;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);

        position: absolute;
        content: '';
        width: 1em;
        height: 1em;
        margin-left: -1.3em;
        margin-top: .2em;
        line-height: inherit;
        background:url('../../assets/images/png/Bullet.png') 50% 50% no-repeat;
        list-style:none;
        padding-left:10px;
    }

    // li:first-child::before {
    //     top: 17%;
    // }

   }

   .clutch-section .procedure-list {
    //    li:last-child::before {
    //     top: 20%; 
    //    }
   }

   .clutch-review-btn {
    // background: #17313B;
    color: var(--white);
    padding: 12px 24px;
    margin-top: 25px;
}

    .bg-circle-shape-right {
        position: absolute;
        width: 608px;
        height: 608px;
        right: 0px;
        // left: calc(100% - 550px);
        top: 20px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: -2;
    }

    .google-review-btn {
        // background: #4285F4;
        color: var(--white);
        padding: 12px;
        margin-top: 25px;
        width: 208px;
    }

    .google-section .procedure-list {
        // li:first-child {
        //  top: 16%;
        // }
    }

    .google-section .procedure-list {
        // li:last-child::before {
        //  top: 50%; 
        // }
    }

    .facebook-section .right-circle-bg-image {
        bottom: -100px;
    }

    .bg-circle-shape-left-1 {
        position: absolute;
        width: 608px;
        height: 608px;
        left: -100px;
        top: 1420px; 
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        z-index: -1;
    }

    .google-firm-section .procedure-list {
        // li:first-child::before, li:last-child::before {
        //     top: 23%;
        // }
    }

    .google-firms-signup-btn {
        // background: #3A7AF3;
        color: var(--white);
        padding: 12px;
        margin-top: 25px;
        width: 208px;
        margin-right: 20px;  
    }

    .google-firms-btn {
    //    border: 1px solid var(--dark-color);
       color: var(--dark-color);
       padding: 12px;
       margin-top: 25px;
       width: 208px;
       border: 1px solid #1A1A1A;
       background: transparent;
    }

    .bg-circle-shape-right-1 {
        position: absolute;
        width: 608px;
        height: 608px;
        // left: 1640px;
        right: 0;
        top: 2012px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: -2;
    }

    // .facebook-section .procedure-list li::before {
    //     top: 46%;
    // }

    .neoito-facebook-btn {
        // background: #4267B2;
        color: var(--white);
        padding: 12px;
        margin-top: 25px;
        width: 208px;
    }

    .bg-circle-shape-left-1 {
        position: absolute;
        width: 608px;
        height: 608px;
        left: -200px;
        top: 1420px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
    }

    .bg-circle-shape-left-2 {
        position: absolute;
        width: 608px;
        height: 608px;
        left: -200px;
        top: 2600px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        z-index: -1;
    }

    .bg-circle-shape-right-2 {
        position: absolute;
        width: 608px;
        height: 608px;
        // left: 1640px;
        right: 0;
        top: 3330px;
        background: linear-gradient(225deg, #FAF0F0 15.17%, rgba(255, 236, 236, 0) 60.49%);
        border-radius: 304px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: -2;
    }

    .testimonial-section .right-circle-bg-image {
        top: 12%;
    }

    .testimonial-section p {
        margin-top: 10px;
    }

    .testimonial-section .procedure-list {
        // li:first-child::before {
        //     top: 50%;
        // }
        // li:nth-child(4)::before, li:nth-child(5)::before  {
        // top: 25%;
        // }
    }

    // .testimonial-video-section #testimonial-video {
    //     border: 4px solid #1F1F22;
    //     border-radius: 10px;
    //     box-sizing: border-box;
    //     // object-fit: fill;
    //     position: relative;
    //     // height: 350px;
    //     // width: 497px;
    //     padding-bottom: 65.25%;
    //     padding-top: 30px;
    //     height: 0;
    //     width: -webkit-fill-available;
    //     max-width: 497px;
    //     max-height: 350px;
    //     overflow: auto;
    // }

    .testimonial-video-section #testimonial-video iframe {
        // height: 100%;
        // width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .testimonial-video-section video {
        border: 4px solid #1F1F22;
        border-radius: 10px;
        box-sizing: border-box;
        object-fit: cover;
        position: relative;
    }

    .testimonial-video-section #playbtn {
        background-color: transparent;
        border: medium none;
        color: #fff;
        font-size: 18px;
        margin: 0 auto;
        padding: 8px 16px;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%,50%);
        display: flex;
        align-items: center;

        img {
          height: 30px;
        }
    }

    .testimonial-video-section video > button.active {
        // background-color: #0077a2;
        display: none;
      }

      .testimonial-section .modal-video-close-btn {
         background: transparent;
      }
}

.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // border: 1px solid #ccc;
    // background: #000;
    overflow: auto;
    // border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .close-btn {
        float: right;
        cursor: pointer;
        // position: absolute;
        display: block;
        padding: 8px 12px 10px;
        line-height: 20px;
        // right: -15px;
        // top: -15px;
        font-size: 50px;
        margin-bottom: 10px;
        // background: #ffffff;
        // border-radius: 22px;
        // border: 2px solid #919191;
         color: #FFFFFF;
    }

    video {
        margin: auto;
    }
  }

.social-media-review-section {
  background: linear-gradient(247.15deg, #FFFAE6 35.18%, #FFFFFF 100%), #FFFAE6;

  .social-media-section-title span {
    color: var(--orange);
 }

 .description-part{
  .procedure-list {
    li {
    position: relative;
    padding-left: 20px;
    margin: 18px 0;
    font-size: 18px;
    line-height: 25.6px;
    }
    li:before {
        // content: '';
        // width: 13px;
        // height: 13px;
        // position: absolute;
        // background-image: url('../../assets/images/png/Bullet.png');
        // background-size: cover;
        // background-position: center;
        // left: 0;
        // top: 16%;
        // transform: translateY(-50%);


        position: absolute;
        content: '';
        width: 1em;
        height: 1em;
        margin-left: -1.5em;
        margin-top: .2em;
        line-height: inherit;
        background:url('../../assets/images/png/Bullet.png') 50% 50% no-repeat;
        list-style:none;
        padding-left:10px;
    }

    // li:first-child::before {
    //     top: 23%;
    // }

    // li:last-child::before {
    //     top: 25%;
    // }
}
}
 .procedure-list span, .procedure-list p {
    font-weight: 600;
}
  
  .social-media-review-btn {
    background: #524C4B;
    padding: 12px;
    margin-top: 25px;
    color: var(--white);
    width: 330px;
}
 
.right-review-section {
    background: url('../../assets/images/png/SocialMediaBg.png') no-repeat center center;
    background-size: cover;
    background-color: #242526;

    .preview-section {
      background-color: #242526;
      border: 1px solid #545350;
      width: 70%;
      border-radius: 8px;
    }

    .header {
      border-bottom: 1px solid #545350;
      h3 {
      color: #FFFFFF;
      font-size: 22px;
    }
  }
}

.rc-tabs-tab {
    color: #FFFFFF;
    border-bottom: 2px solid #545350;
    svg {
        fill: #FFFFFF;
        margin-right: 0;
        width: 16px;
    }

    .seperator {
      width: 1px;
      margin-left: 5px;
      margin-right: 8px;
      background: #FFFFFF;
      height: 8px;
    }
    
}

.rc-tabs-top .rc-tabs-tab {
    margin-right: 8px;
}

.template-tabs .rc-tabs-ink-bar {
    display: none !important;
}

.template-tabs .rc-tabs-nav-wrap {
    border: none;
}

.template-tabs .template-text-section {
    border: none;
}

.template-tabs .rc-tabs-content {
   color: #FFFFFF;
}

.template-tabs .template-text-section {
    min-height: 250px;
    padding: 0;
    text-align: left;
}


.rc-tabs-tab-active, .rc-tabs-tab-active:hover {
    background-color: #1877F2;
    border-radius: 4px;
    border: none;

    svg {
      fill: #FFFFFF;
    }
  }

  .rc-tabs-tab:hover {
      color: #FFFFFF;

      svg {
          fill: #FFFFFF;
      }
  }

}

.preview-section .copy-template-btn {
    background: #FF6D41;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
  }

#footer {
  background: var(--dark-color);
}

#footer .footer-top-section {
    border-bottom: 1px solid #454547;
}

#footer .footer-top-section .footer-logo {
    height: 80px;
}

#footer .policies-section a:hover {
        color: #ff6e43;
}

#footer .footer-top-section {
    .icon-facebook svg, .icon-twitter svg, .icon-linkedin svg, .icon-instagram svg {
        fill: #FFF9F6;
    }
    .icon-facebook:hover svg {
        fill: #3b5998;
    }

    .icon-twitter:hover svg {
        fill: #00acee;
    }

    .icon-linkedin:hover svg {
        fill: #0e76a8;
    }

    .icon-instagram:hover svg {
        fill: #3f729b;
    }
}


@media screen and (max-width: 1200px) {
    
}

@media only screen and (max-width: 1024px) {

.endorse-page-header {
    height: 992px;

    .heaader-part-2 {
        align-items: flex-end;
        padding: 0 3rem;

        > div {
            width: 100%;
            margin-left: 0;
        }

        figure::before {
            top: -147px;
            height: 155px;
        }

    }

}

  .video-modal {
    height: 100%;
    display: flex;
    flex-direction: column; 
    background: transparent;
   }

   .video-modal .close-btn {
     color: #FFFFFF;
   }

   video {
     margin: auto 0;
   }
    
}


.endorse-accordion-section {

    padding-left: 6rem;
    padding-right: 6rem;

    .accordion__item {
        margin-bottom: 2rem;
    }

    .accordion__heading {
      font-size: 24px;
      font-weight: 600;
    }

    .accordion__button {
        background: #1A1A1A !important;
        border-radius: 4px;
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .accordion-header-title span {
          color: var(--orange);
        }
    }

    .accordion__button[aria-expanded='true'],.accordion__button[aria-selected='true'] {
        background: #365BAC !important;
    }

    .accordion__button:before {
        display: none;
    }

    .accordion__button::after {
        display: block;
        content: '';
        height: 28px;
        width: 28px;
        margin-right: 6px;
        background: url('../../assets/images/svg/arrow-down-1.svg') center no-repeat;
        background-color: #333333;
        border-radius: 50%;
        padding: 20px;
    }

    .accordion__button[aria-expanded='true']::after, .accordion__button[aria-selected='true']::after {
        background-color: #2E4E93;      
        transform: matrix(1, 0, 0, -1, 0, 0);
    }

    .accordion__panel {
        padding: 10px;
    }

    .accordion__panel img, .accordion__panel video {
        width:100%; 
        height: auto;
    }

    // .accordion__panel .procedure-list li:first-child::before, .accordion__panel .procedure-list li:last-child::before {
    //     top: 25%;
    // }

    // .accordion__item:nth-child(5) > .accordion__panel .procedure-list li:nth-child(2)::before {
    //     top: 18%;
    // }

    // .accordion__item:last-child > .accordion__panel   .procedure-list li::before,
    // .accordion__item:nth-child(4) > .accordion__panel   .procedure-list li::before {
    //     top: 50%;
    // }



    .testimonial-video-section #testimonial-video {
        max-width: 100%;
    }

    .procedure-list {
        span, p {
          font-weight: 600;
        }
    }

    .social-media-review-btn {
        background: #524C4B;
        padding: 12px;
        margin-top: 25px;
        color: var(--white);
        width: 330px;
    }

    .social-media-review-part {
        background: url('../../assets/images/png/SocialMediaBg.png') no-repeat center center;
        background-size: cover;
        background-color: #242526;
        display: flex;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .social-media-section {
        padding: 0.5rem;
        margin-top: 1rem;
    }

    .preview-section {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
        background-color: #242526;
        border: 1px solid #545350;
            // width: 70%;
        border-radius: 8px;
        width: 80%;
        .header {
          border-bottom: 1px solid #545350;
          padding-top: 0;
          padding-bottom: 0;
        }

      .header h3 {
      text-align: center;
      padding: 0.5em;
      color: #FFFFFF;
      font-size: 22px;
      font-weight: 500;
    }
}

    .template-text-section {
        color: #FFFFFF;
    }

    .rc-tabs-tab {
        color: #FFFFFF;
        border-bottom: 2px solid #545350;
        svg {
            fill: #FFFFFF;
            margin-right: 0;
            width: 16px;
        }
    
        .seperator {
          width: 1px;
          margin-left: 5px;
          margin-right: 8px;
          background: #FFFFFF;
          height: 8px;
        }
        
    }

    .rc-tabs-top .rc-tabs-tab {
        margin-right: 8px;
    }

    .template-tabs .rc-tabs-ink-bar {
        display: none !important;
    }
    
    .template-tabs .rc-tabs-nav-wrap {
        border: none;
    }
    
    .template-tabs .template-text-section {
        border: none;
        min-height: 300px;
    }
    
    .template-tabs .rc-tabs-content {
       color: #FFFFFF;
    }
    
    
    .rc-tabs-tab-active, .rc-tabs-tab-active:hover {
        background-color: #1877F2;
        border-radius: 4px;
        border: none;
    
        svg {
          fill: #FFFFFF;
        }
      }
    
      .rc-tabs-tab:hover {
          color: #FFFFFF;
    
          svg {
              fill: #FFFFFF;
          }
      }

    .copy-template-btn {
      margin-top: 1rem;
    } 
}

@media only screen and (max-width: 767px) {
  .endorse-page-header .header-part-1 {
      padding: 1rem;
   }

  .endorse-page-header .header-part-1 .logo-section img {
      width: 46px;
  }

  .endorse-page-header .title-container {
      margin-top: 5rem;
  }

  .endorse-page-header .title-container > div {
      width: 100%;
      height: calc(100%/2);
      justify-content: center;
      padding: 1rem;
  }

  .endorse-page-header .header-part-1 .title-container img {
    height: 450px;
    z-index: 0;
  }


  .endorse-page-header .header-part-1 p {
   font-size: 16px;
   margin-top: 15px;
  }

  .endorse-page-header .header-part-1 .endrose-now-btn {
      margin-top: 0;
      width: 139px;
      margin-bottom: 0;
  }

  .heaader-part-2 > div {
      width: 100% !important;
      flex-direction: column;
      margin-left: 0;
      padding-left: 1rem;
      padding-right: 1rem;
  }

  .endorse-page-header .heaader-part-2 {

    z-index: 8;
    padding: 1rem;
    height: 40%;

    .gift-voucher-part {
        figure {
            position: relative;
            margin-top: 20px;
            height: 50%;
            width: auto;
            align-self: center;

            p {
                padding: 0;
                margin-top: 0;
            }

            .tag-img-name {
              left: 0;
              top: 25%;
              margin-top: 0;
            }
        }
    }

    figure::before {
      display: none;
    }
    
    figure, p {
      width: 100%;
      height: 50%;
    }

    p {
        margin-top: 10px;
        margin-left: 0;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

  }

  .endorse-accordion-section {
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem;

      .endrose-section .bg-circle-shape-left {
          top: 100px;
      }

      .preview-section {
          .header {
              padding-left: 0.75rem;
              padding-right: 0.75rem;
          }

          .templates-tab-container {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
          }
      }

      .template-tabs .template-text-section {
          text-align: left;
      }
  }

  .video-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
   }

  .video-modal {
    height: 100%;
    display: flex;
    flex-direction: column; 
    background: transparent;
   }

   .video-modal .close-btn {
     color: #FFFFFF;
   }

   video {
     margin: auto 0;
   }

  #footer .footer-bottom-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
}

@media screen and (max-width: 532px) {

    .endrose-section h3 {
        font-size: 25px;
      }

      .header-img {
          margin-top: 10px;
          padding: 5px;
      }

      .endorse-page-header {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }
  
      .endorse-page-header .heaader-part-2 .gift-voucher-img {
          margin: 0 auto;
          height: 100%;
      }

      .endorse-page-header .title-container > div {
          padding: 0.5rem;
      }

    .endorse-page-header .header-title-wrapper {
          margin-bottom: 2.5rem;
    }
  
      .endorse-page-header .heaader-part-2 figure, .endorse-page-header .heaader-part-2 p {
          padding: 0.5rem;
          font-size: 16px;
      }
  
      .endrose-section .bg-circle-shape-left, .endrose-section .bg-circle-shape-right {
          width: 327px;
          height: 327px;
      }
  
      .endrose-section .bg-circle-shape-left {
       top: 120px;
      }
  
      .endrose-section .bg-circle-shape-right {
          top: 60%;
      }
  
      .endorse-accordion-section .accordion__button {
          font-size: 20px;
      }

      .endrose-section .social-media-review-btn {
          width: 285px;
      }

    .endorse-page-header .header-part-1 {
        height: 55%;

    .header-title {
        font-size: 25px;
    
    span {
        font-size: 16px;
    }

    .endorse-page-header .header-part-1 .title-container img {
        height: 400px;
    }

   }
}

    .endorse-page-header .heaader-part-2 {
      height: 45%;
    }

    .endorse-page-header .heaader-part-2 > div {
        align-self: center;
        height: 100%;
        padding: 0;
    }

    .endorse-accordion-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .thanks-page button {
        font-weight: 500;
        font-size: 15px;
    }


    .accordion__panel {
    //   .clutch-section, .goodfirms-section, .google-review-section {
    //    .procedure-list li:first-child::before {
    //       top: 17% !important;
    //   }
    // }
    //   .clutch-section, .goodfirms-section {
    //   .procedure-list li:last-child::before {
    //     top: 17% !important;
    //   }
    //  }
   }

   .social-media-review-part .preview-section {
       width: 93%;
   }

   .endorse-accordion-section .preview-section .templates-tab-container {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
   }

   .endorse-accordion-section .template-text-section {
      padding: 0.5rem;
   }

    footer {
        .footer-bottom-section {
            flex-direction: column-reverse;

            h6 {
                padding: 2rem 1rem;
                margin-left: 0.75rem;
                border-top: 1px solid #454547;
                margin-right: 0.75rem;
            }

            .policies-section {
                padding: 2rem 1rem;
                display: grid;
                grid-template-columns: repeat(2, 145px);
                grid-auto-rows: 50px;
                grid-gap: 0;
            }
        }
    }

}

@media only screen and (min-width: 1030px) and (max-width: 1366px){
    .title-container {
        .header-title-wrapper {
            margin-left: 2.5rem;
        }
    }

    .heaader-part-2 {
        .gift-voucher-part {
           padding-left: 2.5rem;
        }
    }

    .endorse-page-header .header-part-1 .header-img img {
        height: 510px;
    }

    .gift-voucher-part {
        padding-right: 1rem;
        width: 60%;
    }

    .endorse-page-header .heaader-part-2 figure::before {
        top: -92px;
        height: calc(100% - 55px);
    }

    .description-part {
        width: 85% !important;
    }
}
